import { IEnvironment } from "./environment.model";

/*
  NOTE:
  1. Add all your environment key-values in environment.json file.
  2. If key names are same then - values from environment.json file will override the values present over here. 
    Else it will append to environment variables.
*/
const defaultConfig: IEnvironment = {
  version: "",
  production: false,
  aksPodEnv: "",
  isAzureFrontDoorEnabled: "",
  azureFrontDoorUrl: "",
  legacyGemFrontEndUrl: "",
  defaultUserRegion: "",
  hostedApplicationRegion: "",
  frontendUrl: "",
  backendUrl: "",
  globalBackendUrl: "",
  azureSignalRUrl: "",
  pursuitTeamCrmURL: "",
  gemFrontEndUrl: "",
  baseApiEndPoint: "",
  CRMLink: "",
  competitiveAdvantageCountries: "",
  globalAPIurl: "",
  paansUrl: "",
  basePaansUrl: "",
  faqUrl: "",
  cctLink: "",
  sowReviewLink: "",
  contentManagementLink: "",
  surveyUrl: "",
  taawUrl: "",
  solutionPlanEnablement: "",
  smartAssistantUrl: "",
  globalConsulting: {
    name: "",
    url: ""
  },
  adobeEnvironment: "",
  adobeLaunchFile: "",
  viewProfileUrl: "",
  graphAPIscopes: [],
  idleSession: {
    startingSeconds: "",
    startingMinutes: "",
    timeoutDuration: ""
  },
  deliveryPerformanceQuickLinks: {
    essentialsForEngagementLink: "",
    sqToolLink: ""
  },
  graphApi: {
    url: "",
    scopes: []
  },
  discoverSmarthubScope: "",
  msalConfig: {
    instance: {
      auth: {
        clientId: "",
        authority: "",
        redirectUri: "",
        postLogoutRedirectUri: "",
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: "",
        storeAuthStateInCookie: false
      },
      scopes: []
    },
    guard: {
      interactionType: "",
      authRequest: {
          scopes: []
      },
      loginFailedRoute: ""
    }
  },
  microservicesUrl: {
    default: "",
    global: "",
    collaboration: "",
    content: "",
    deal: "",
    delivery: "",
    master: "",
    notification: "",
    sq: "",
    sqworkbench: "",
    user: "",
    aggregatorDeal: "",
    aggregatorWorkspace: "",
    aggregatorContent: "",
    smartAssitant: "",
    aggregatorDelivery: "",
    aggregatorRecommendation: "",
    aggregatorReporting: ""
  },
  globalAPIResourceAccess: ""
};

let environmentData: IEnvironment;
export const environmentFileUrl = "/environment.json";

export function SetEnvConfig(env: IEnvironment) {
  if (env) {
    environmentData = env;

    // NOTE: If Azure frontdoor is enabled then update the backend url
    env.isAzureFrontDoorEnabled === "true" && (environmentData.backendUrl = env.azureFrontDoorUrl);
  }
}

export function GetEnvConfig(): IEnvironment {
  return environmentData ?? defaultConfig;
}
